<template>
  <div class="main">
    <b-row class="mb-5">
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Từ"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.fromDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Đến"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.toDate"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-form>
          <b-form-select
            v-model="apiParams.type"
            :options="BILL_TYPE"
            class="select-style"
            size="sm"
          ></b-form-select>
        </b-form>
      </b-col>
      <b-col md="1">
        <b-button
          style="width: 70px"
          variant="primary"
          size="sm"
          @click="fetchMainData()"
        >Lọc
        </b-button>
      </b-col>
    </b-row>
    <b-table-simple class="table-bordered table-hover tbody-hover col-md-12">
      <b-thead>
        <b-tr>
          <b-th class="text-center td-date">Ngày</b-th>
          <b-th class="text-center td-code">ID</b-th>
          <b-th class="text-center td-store">Cửa hàng</b-th>
          <b-th class="text-center td-customer">Khách hàng</b-th>
          <b-th class="text-center td-product-name">Sản phẩm</b-th>
          <b-th class="text-center td-product-price">Giá</b-th>
          <b-th class="text-center td-quantity">SL</b-th>
          <b-th class="text-center td-discount-item">C.Khấu</b-th>
          <b-th class="text-center td-total-amount">Tổng tiền</b-th>
          <b-th class="text-center td-total-payment">Thanh toán</b-th>
          <b-th class="text-center td-note">Ghi chú</b-th>
          <b-th class="text-center "></b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-for="bill in mainList.dataset"
        :key="bill.id"
      >
        <b-tr
          v-for="(item, index) in bill.billItems"
          :key="index"
        >
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="td-date"
            v-show="isShowRow(bill.billItems, item)"
          >
            {{ formatDate(bill.createdAt) }}
            <p
              class="mb-0"
              v-if="bill.cashierName"
            >
              {{ bill.cashierName }}
            </p>
            <p v-if="bill.saleName">
              <b class="mb-0 ">
                NVBH:
                <br />
              </b>
              <span>{{ bill.saleName }}</span>
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-center td-code"
            v-show="isShowRow(bill.billItems, item)"
          >
            <div>
              <router-link
                :to="{
                  path: '/bills/update-bill',
                  query: { id: bill.billNumber }
                }"
                target="_blank"
              >{{ bill.billNumber }}
              </router-link>
            </div>
            <div class="text-center">
              <i
                v-if="bill.type === 4"
                class="fas fa-shipping-fast text-success"
                v-b-tooltip.hover
                title="Hóa đơn giao hàng từ đơn hàng"
              ></i>
              <i
                v-if="bill.type === 1 && bill.orderId"
                class="fas fa-shopping-basket text-success"
                v-b-tooltip.hover
                title="Hóa đơn từ đơn hàng"
              ></i>
              <i
                v-if="bill.type === 1 && !bill.orderId"
                class="fas fa-shopping-bag text-infor"
                v-b-tooltip.hover
                title="Hóa đơn bán lẻ"
              ></i>
              <i
                v-if="bill.type === 2"
                class="fas fa-shopping-cart text-warning"
                v-b-tooltip.hover
                title="Hóa đơn bán buôn"
              ></i>
              <i
                v-if="bill.type === 3"
                class="fas fa-undo-alt text-dark"
                v-b-tooltip.hover
                title="Hóa đơn trả hàng"
              ></i>
              <div
                v-if="bill.orderId"
                class="orderCode"
                @click="redirectToOrder(bill.orderId)"
              >
                <span style="color: darkgoldenrod">{{ bill.orderId }}</span>
              </div>
            </div>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left td-store"
            v-show="isShowRow(bill.billItems, item)"
          >
            {{ bill.storeName }}
            <br />
            <span
              v-if="bill.companyName"
              style="font-size: 10px"
            >( {{ bill.companyName }} )</span>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left tdCenter td-customer"
            v-show="isShowRow(bill.billItems, item)"
          >
            <b> {{ bill.customerName }}</b>
            <p>{{ bill.customerMobile }}</p>
          </b-td>
          <b-td class="text-left td-product-name">
            <div v-if="item.productType !== 3">
              <div
                v-if="item.productType === 2"
                class="font-size-gift"
              >
                <i
                  style="color: #2e7d32"
                  class="fas fa-gift ml-4 mr-1"
                  title="Quà tặng"
                ></i>
                {{ item.productName }}
              </div>
              <div
                v-else-if="item.productType === 3"
                class="font-size-attach"
              >
                <i
                  style="color: #37474f"
                  class="fas fa-tags ml-4 mr-1"
                  title="Bán kèm"
                ></i>
                {{ item.productName }}
              </div>
              <div v-else>
                {{ item.productName }}
              </div>
              <p
                class="text-imei-no"
                v-if="item.imeiCode.trim() !== ''"
              >
                IMEI: {{ item.imeiCode }}
              </p>
            </div>
            <div v-else>
              <div
                class="table-responsive"
                v-show="
                  item.comboItems !== undefined && item.comboItems.length > 0
                "
              >
                <table>
                  <tr>
                    <td style="border: none; padding-left: 0">
                      <p class="m-0">
                        {{ item.productName }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-for="(items, i) in item.comboItems"
                    :key="i"
                    style="border-top: 1px solid gray"
                  >
                    <td style="
                                border: none;
                                width: -webkit-fill-available;
                              ">
                      <i
                        style="color: rgb(24, 28, 50)"
                        class="fas fa-cube"
                      ></i>
                      {{ items.productName }}
                      <i style="font-size: unset; color: unset">({{ convertPrice(items.sellingPrice) }})</i>
                    </td>
                    <td style="border: none; font-size: 13px">
                      {{ items.quantity }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-td>
          <b-td class="text-right  td-product-price">
            {{ convertPrice(item.productPrice) }}
          </b-td>
          <b-td class="text-right tdCenter td-quantity">{{
            item.quantity
          }}</b-td>
          <b-td class="text-right td-discount-item">
            <p v-if="item.discountType === 1">
              {{ convertPrice(item.discountAmount) }}
            </p>
            <p v-if="item.discountType === 2 && item.discountAmount > 0">
              {{ convertPrice(item.discountAmount) }}%
              <br />
              <span style="color: #ffa800; font-style: italic">
                {{ convertPrice(item.discountPercentAmount) }}</span>
            </p>
            <p v-if="item.discountType === 2 && item.discountAmount === 0">
              {{ convertPrice(item.discount) }}
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-right tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10% ;color: #3c763d; font-weight: 700"
          >
            <p
              v-show="bill.discountAmount"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Chiết khấu"
                class="fa fa-arrow-down icon-color"
              ></i>
              :
              {{
                bill.discountType === 2
                  ? bill.discountAmount + '%'
                  : convertPrice(bill.discountAmount)
              }}
            </p>
            <span>
              {{ convertPrice(bill.totalAmount) }}
            </span>
            <p
              v-show="bill.otherFrees > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Phí quẹt thẻ thu thêm khách"
                class="fad fa-money-bill-alt"
              ></i>
              : <b>{{ convertPrice(bill.otherFees) }}</b>
            </p>
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-right tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10%"
          >
            <p
              v-show="bill.pointToMoney > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Tiêu điểm"
                class="fas fa-adjust icon-color"
              ></i>
              : <b>{{ convertPrice(bill.pointToMoney) }}</b>
            </p>
            <p
              v-show="bill.transferAmount > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Đã chuyển khoản"
                class="fa fa-university icon-color"
              ></i>
              : <b>{{ convertPrice(bill.transferAmount) }}</b>
            </p>
            <p
              v-show="bill.creditAmount > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Tiền quẹt thẻ"
                class="fa fa-credit-card icon-color"
              ></i>
              : <b>{{ convertPrice(bill.creditAmount) }}</b>
            </p>
            <p
              v-show="bill.cashAmount > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Tiền mặt"
                class="fas fa-money-bill-alt icon-color"
              ></i>
              : <b>{{ convertPrice(bill.cashAmount) }}</b>
            </p>
            <p
              v-show="bill.installedMoneyAmount > 0"
              class="border-0"
            >
              <i
                v-b-tooltip.hover
                title="Trả góp"
                class="fa fa-cube icon-color"
              ></i>
              : <b>{{ convertPrice(bill.installedMoneyAmount) }}</b>
            </p>

            <b
              v-if="bill.extraAmount < 0"
              class="d-block text-primary mb-2"
            >
              Tiền thừa:
              <span class="d-block ">{{
                convertPrice(bill.extraAmount * -1)
              }}</span>
            </b>
            <b
              v-if="bill.extraAmount > 0"
              class="d-block mb-2"
              style="color: #e65b5b"
            >
              Còn thiếu:
              <span class="d-block"> {{ convertPrice(bill.extraAmount) }}</span>
            </b>
            <p v-show="bill.leftAmount > 0">
              <b>
                Công nợ:
                <span
                  class="d-block"
                  v-text="convertPrice(bill.leftAmount)"
                ></span></b>
            </p>
          </b-td>

          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-left tdCenter"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 10%"
          >
            {{ bill.saleNote }}
          </b-td>
          <b-td
            :rowspan="bill.billItems.length + 1"
            class="text-center"
            v-show="isShowRow(bill.billItems, item)"
            style="width: 5%"
          >
            <b-dropdown
              size="sm"
              no-caret
              right
              lazy
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="flaticon2-settings pr-0"
                ></i>
              </template>

              <b-dropdown-item
                @click="returnBill(bill)"
                v-if="bill.canReturnBill"
              >
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-reply-1"
                  ></i>
                  &nbsp; Trả hàng
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="
                  makeToastFaile('Không thể trả hàng quá số ngày qui định')
                "
              >
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-reply-1"
                  ></i>
                  &nbsp; Trả hàng
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <tr>
          <td
            colspan="6"
            class="text-right"
          >
            <b>Tổng</b>
          </td>
          <td class="text-right">
            <b>{{ sumQty }}</b>
          </td>
          <td class="text-right">
            <b>{{ convertPrice(sumAmount('discountAmount')) }}</b>
          </td>
          <td class="text-right">
            <b>
              {{ convertPrice(sumAmount('totalAmount')) }}
            </b>
          </td>
          <td class="text-right">
            <b>
              {{ convertPrice(sumAmount('totalAmount')) }}
            </b>
          </td>
          <td></td>
        </tr>
      </b-tfoot>
    </b-table-simple>
    <vcl-table
      v-if="onLoadingList"
      :speed="5"
      :animate="true"
      :columns="11"
    />
    <b-row>
      <b-col
        lg="3"
        md="3"
        sm="12"
      >
        <p
          class="mt-3 text-dark"
          style="font-weight: 500"
        >
          Tổng số:
          {{ mainList.total }}
        </p>
      </b-col>
      <b-col
        lg="9"
        md="9"
        sm="12"
      >
        <BPaginationNavCustom
          :number-of-pages="mainList.pages"
          :current-page="currentPage"
          :total-page="mainList.total"
          :linkParams="linkParams"
          @page-change="fetchMainData(pageInfo)"
        >
        </BPaginationNavCustom>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  width: 100%;
  .break-line {
    white-space: break-spaces;
  }
  .icon:hover {
    background-color: #90c6fc;
  }
  .text-imei-no {
    font-size: 12px;
    font-style: italic;
    color: dodgerblue;
  }
  .td-date {
    width: 5%;
    font-size: 11px;
  }
  .td-code {
    width: 5%;
  }
  .td-store {
    width: 5%;
  }
  .td-customer {
    width: 7%;
  }
  .td-product-name {
    width: 15%;
  }
  .td-product-price {
    width: 8%;
  }
  .td-quantity {
    width: 3%;
  }
  .td-discount-item {
    width: 5%;
  }
  .td-total-amount {
    width: 8%;
  }
  .td-total-payment {
    width: 10%;
    font-weight: 700;
  }
  .td-note {
    width: 19%;
  }
  .tdAction {
    width: 5%;
  }
  .wrapword {
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
  }
  .table tbody + tbody {
    border-top: unset !important;
  }
  .table th,
  .table td {
    vertical-align: middle;
  }
  .tdCenter {
    vertical-align: left !important;
  }
  .tdTextAlign {
    vertical-align: middle;
  }
  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
  }
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  .font-size-gift {
    font-size: 11px;
  }
  .font-size-attach {
    font-size: 11px;
  }
  .orderCode:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
import { formatDate, convertPrice, makeToastFaile } from '@/utils/common';
import { BILL_TYPE } from '@/utils/constants';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
export default {
  props: ['customerId'],
  components: {
    VclTable,
    datePicker,
    BPaginationNavCustom,
  },
  data() {
    return {
      linkParams: {},
      customerKey: null,
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        fromDate: null,
        toDate: null,
        type: null,
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      currentPage: 1,
      BILL_TYPE,
    };
  },
  mounted() {
    this.fetchMainData();
  },
  methods: {
    formatDate,
    convertPrice,
    makeToastFaile,
    fetchMainData: function (params) {
      this.getParamFilters();

      this.onLoadingList = true;
      ApiService.query(
        cmdUrl.BillUrl.byCustomer.replace('{id}', this.customerKey),
        { params: params ? params : { ...this.apiParams } },
      )
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    getParamFilters() {
      this.currentPage = this.$route.query.page;
      if (this.currentPage === undefined) {
        this.currentPage = 1;
      }
      this.customerKey = this.$route.query.phoneNo || this.$route.query.id;

      if (!this.currentPage) {
        this.currentPage = 1;
      }

      this.apiParams.page = this.currentPage;
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    calculateDiscountPercent: function (bill) {
      const discount = bill.discountAmount || 0;
      const total = bill.totalAmount || 0;
      if (total !== 0) {
        return parseFloat((discount / total) * 100).toFixed(1);
      }
      return 0;
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0,
      );
    },
    redirectToOrder: function (orderId) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: orderId },
      });
      window.open(href, '_blank');
    },
    returnBill: function (item) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: item.billNumber },
      });
    },
  },
  watch: {
    customerKey: {
      handler(newVal, val) {
        if (newVal !== val) {
          this.linkParams = {
            id: this.customerKey,
          };
        }
      },
    },
  },
  computed: {
    sumQty: function () {
      let sum = 0;
      _.map(this.mainList.dataset, (item) => {
        const tmpSum = _.sumBy(item.billItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    },
  },
};
</script>
