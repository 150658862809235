<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="''">
          <template v-slot:preview>
            <b-card no-body no-footer class="full-width">
              <!-- BEGIN WIZARD -->

              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!-- NAVIGATION -->
                <div class="wizard-nav bg-nav-light">
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin khách hàng</div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử hoá đơn</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử đơn hàng</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử thu cũ</div>
                        </div>
                      </div>
                    </div>
                    <div class="wizard-step ml-1" data-wizard-type="step">
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Số điện thoại</div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- CONTENT -->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-10 px-lg-5">
                      <div class="col-xl-12">
                        <form class="form mt-0">
                          <div
                            class="full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-md-12 full-width">
                              <!-- <div
                                class="alert mb-5 p-5 alert-custom"
                                role="alert"
                              >
                                <b-row class="w-100">
                                  <b-col md="3" cols="12">
                                    <div class="mb-2">
                                      <span class="font-weight-bold mr-1"
                                        >Tổng tiền tiêu dùng:</span
                                      >
                                      <span class="text-danger font-weight-bold"
                                        >{{
                                          convertPrice(
                                            customer.totalAmountBuyedBill,
                                          ) || 0
                                        }}
                                        đ</span
                                      >
                                    </div>
                                    <div class="mb-2">
                                      <span class="font-weight-bold mr-1"
                                        >Điểm tích lũy:</span
                                      >
                                      <span
                                        class="text-danger font-weight-bold"
                                        >{{ customer.totalPoint }}</span
                                      >
                                    </div>
                                    <div class="mb-2">
                                      <span class="font-weight-bold mr-1"
                                        >Loại khách hàng:</span
                                      >
                                     
                                      <span
                                        class="text-danger font-weight-bold"
                                        :title="
                                          customer.customerType.description
                                        "
                                        v-b-tooltip.hover
                                        v-if="customer.customerType"
                                        >{{ customer.customerType.name }}</span
                                      >
                                      <span
                                        class="text-danger font-weight-bold"
                                        v-else
                                        >--</span
                                      >
                                    </div>
                                    <div class="mb-2">
                                      <p class="font-weight-bold mr-1 mb-1">
                                        Thương hiệu đã mua:
                                      </p>
                                      <span v-html="listBrand"></span>
                                    </div>
                                  </b-col>
                                  <b-col md="4" cols="12">
                                    <div class="mb-2">
                                      <p class="mb-1 font-weight-bold">
                                        Cửa hàng đã mua hàng:
                                      </p>
                                      <ul class="list ml-5 mb-0">
                                        <li
                                          class="list-item one"
                                          v-for="(
                                            item, index
                                          ) in customer.store"
                                          :key="index"
                                        >
                                          {{ item.name || '--' }}
                                        </li>
                                      </ul>
                                    </div>
                                  </b-col>
                                  <b-col md="5" cols="12">
                                    <div class="mb-2">
                                      <p class="mb-1 font-weight-bold">
                                        Nhân viên bán hàng:
                                      </p>
                                      <ul class="list ml-5 mb-0">
                                        <li
                                          class="list-item one"
                                          v-for="(
                                            item, index
                                          ) in customer.employees"
                                          :key="index"
                                        >
                                          {{ item.fullName }}
                                          {{
                                            item.code ? '- ' + item.code : ''
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div> -->

                              <b-row class="mb-3">
                                <!-- <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Họ:</label>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="customer.lastName"
                                      required
                                      placeholder="Nhập tên lót"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col> -->
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Tên:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.customer.fullName.$model"
                                      :state="validateState('fullName')"
                                      required
                                      placeholder="Nhập tên"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Vui lỏng nhập tên khách
                                      hàng</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Điện thoại:</label>
                                      <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.customer.phoneNumber.$model"
                                      :state="validateState('phoneNumber')"
                                      required
                                      :disabled="true"
                                      placeholder="Nhập điện thoại"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Số điện thoại không thể để
                                      trống</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                                <!-- <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Email:</label>
                                    </template>
                                    <b-form-input
                                      type="email"
                                      size="sm"
                                      v-model="$v.customer.email.$model"
                                      :state="validateState('email')"
                                      required
                                      placeholder="Nhập email"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Email không đúng định
                                      dạng</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col> -->
                              </b-row>

                              <div class="row mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Email:</label>
                                    </template>
                                    <b-form-input
                                      type="email"
                                      size="sm"
                                      v-model="$v.customer.email.$model"
                                      :state="validateState('email')"
                                      required
                                      placeholder="Nhập email"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                      id="input-live-feedback"
                                      >Email không đúng định
                                      dạng</b-form-invalid-feedback
                                    >
                                  </b-form-group>
                                </b-col>
                                <b-col cols="5">
                                  <b-row>
                                    <b-col cols="4">
                                      <b-form-group>
                                        <template>
                                          <label>Ngày sinh:</label>
                                        </template>

                                        <date-picker
                                          placeholder="Chọn ngày sinh"
                                          class="form-control form-control-sm"
                                          :config="dpConfigs.date"
                                          v-model="customer.dateOfBirth"
                                        ></date-picker>
                                      </b-form-group>
                                    </b-col>
                                    <b-col cols="3" class="pr-1">
                                      <b-form-group class="required-control">
                                        <label>Giới tính:</label>
                                        <b-form-select
                                          size="sm"
                                          required
                                          v-model="customer.gender"
                                          :options="listGender"
                                          class="select-style"
                                          value-field="id"
                                          text-field="name"
                                        >
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <!-- <b-col cols="3">
                                  <div class="gender-appellation">
                                    <b-form-group class="required-control">
                                      <label>Giới tính:</label>
                                      <b-form-select
                                        size="sm"
                                        required
                                        v-model="customer.gender"
                                        :options="listGender"
                                        class="select-style"
                                        value-field="id"
                                        text-field="name"
                                      >
                                      </b-form-select>
                                    </b-form-group>

                                    <b-form-group>
                                      <template>
                                        <label>Danh xưng:</label>
                                      </template>
                                      <b-form-select
                                        size="sm"
                                        required
                                        v-model="customer.appellation"
                                        :options="listAppellation"
                                        class="select-style"
                                        value-field="id"
                                        text-field="name"
                                      >
                                      </b-form-select>
                                    </b-form-group>
                                  </div>
                                </b-col> -->
                                <!-- <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Loại khách hàng:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.type"
                                      :options="listType"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Chọn loại khách</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col> -->
                                <!-- <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Nhân viên phụ trách:</label>
                                    </template>
                                    <Autosuggest
                                      :model="customer.inChargeEmployeeName"
                                      :suggestions="filteredOptionEmployee"
                                      @change="onInputChangeEmployee"
                                      @select="onSelectedEmployee"
                                      aria-describedby="input-src-store"
                                      size="sm"
                                      :limit="10"
                                      placeholder="nhân viên"
                                    >
                                      <template #custom="{ suggestion }">
                                        <div>
                                          <span>{{
                                            suggestion.item.suggestionName
                                          }}</span>
                                        </div>
                                      </template>
                                    </Autosuggest>
                                  </b-form-group>
                                </b-col> -->
                              </div>
                              <!-- <b-row class="mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Thành phố:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.city"
                                      v-on:change="getDistricts(true)"
                                      :options="listCity"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Thành phố --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Quận:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.district"
                                      :options="listDistrict"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                      v-on:change="getWards(true)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Quận --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Phường:</label>
                                    </template>
                                    <b-form-select
                                      size="sm"
                                      required
                                      v-model="customer.ward"
                                      :options="listWard"
                                      class="select-style"
                                      value-field="id"
                                      text-field="name"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >-- Phường --</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-row> -->
                              <b-row class="mb-3">
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Địa chỉ:</label>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="customer.address"
                                      placeholder="Nhập địa chỉ"
                                      required
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                  <b-form-group>
                                    <template>
                                      <label>Ghi chú:</label>
                                    </template>
                                    <b-form-textarea
                                      size="sm"
                                      v-model="customer.note"
                                      :placeholder="'Thêm ghi chú'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>
                            <b-col cols="12">
                              <b-button
                                style="width: 70px"
                                variant="primary"
                                size="sm"
                                type="submit"
                                v-if="checkPermission('CUSTOMER_UPDATE')"
                                @click="updateCustomer"
                                >Lưu</b-button
                              >
                              <b-button
                                class="ml-2"
                                style="width: 70px"
                                @click="$router.go(-1)"
                                variant="secondary"
                                size="sm"
                                >Hủy</b-button
                              >
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerBill
                                  :customerId="$route.query.phoneNumber"
                                />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerOrder :customerId="$route.query.id" />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <CustomerTradeIn
                                  :customerId="$route.query.id"
                                />
                              </b-row>
                            </b-col>
                          </div>
                          <div
                            class="mb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <b-col cols="12" class="full-width">
                              <b-row>
                                <ListPhones :customer="customer" />
                              </b-row>
                            </b-col>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END WIZARD -->
            </b-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}

.list .list-item {
  flex: 0 0 calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
}
.alert-custom {
  border: 1px solid #000;
  box-shadow: 1px 1px 20px #ccc;
}

.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}

.card-header-tabs {
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.label-header-form {
  font-size: 16px;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
textarea.form-control {
  border: 1px solid #ced4da;
}
.bg-nav-light {
  background-color: #eef0f8;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { checkPermission } from '@/utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import CustomerBill from '../../components/CustomerBill.vue';
import CustomerOrder from '../../components/CustomerOrder.vue';
import CustomerTradeIn from '../../components/CustomerTradeIn.vue';
import ListPhones from '../../components/customers/ListPhones.vue';
// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import {
  xoa_dau,
  makeToastSuccess,
  makeToastFaile,
  convertPrice,
} from '@/utils/common';
import {
  CUSTOMER_GENDER,
  CUSTOMER_TYPE,
  CUSTOMER_APPELLATION,
} from '@/utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      isNew: true,
      listType: CUSTOMER_TYPE,
      listCity: [],
      listDistrict: [],
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listAppellation: CUSTOMER_APPELLATION,
      listEmployee: [],
      filteredOptionEmployee: [],
      optionEmployee: [],
      phoneNumber: null,
      customer: {
        id: null,
        fullName: null,
        phoneNumber: null,
        gender: null,
        appellation: null,
        dateOfBirth: null,
        note: null,
        address: null,
        email: null,
        type: null,
        city: null,
        district: null,
        ward: null,
        inChargeEmployeeId: null,
        lastName: null,
        totalPoint: 0,
        inChargeEmployeeName: null,
        brands: null,
        customerType: null,
      },
    };
  },
  validations: {
    customer: {
      fullName: { required },
      phoneNumber: { required },
      email: { email },
      gender: { required },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    // Autosuggest,
    CustomerBill,
    CustomerOrder,
    CustomerTradeIn,
    ListPhones,
  },
  mounted() {
    if (this.checkPermission('CUSTOMER_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Khách hàng', route: '/customers' },
        { title: 'Danh sách khách hàng', route: '/customers' },
        { title: 'Cập nhật khách hàng' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Khách hàng', route: '/customers' },
        { title: 'Danh sách khách hàng', route: '/customers' },
        { title: 'Thông tin khách hàng' },
      ]);
    }

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  created() {
    this.customerId = this.$route.query.id;
    this.phoneNumber = this.$route.query.phoneNumber;
    this.getListEmployee();
    this.getCities();
    this.getCustomerById();
    // this.getStatisticalBillCustomer();
  },
  computed: {
    listBrand() {
      if (this.customer.brands && this.customer.brands.length) {
        const _sortBrandTotalBuy = [...this.customer.brands];
        const sortBrandTotalBuy = _sortBrandTotalBuy.sort(
          (a, b) => b.totalBuy - a.totalBuy,
        );
        const createBrandHtml = (item) => {
          if (item.totalBuy === (sortBrandTotalBuy[0]['totalBuy'] || 2)) {
            return `<span class="text-danger font-weight-bold">${item.name}</span>`;
          } else {
            return item.name;
          }
        };
        const brandHtmlArray = sortBrandTotalBuy.map((item) =>
          createBrandHtml(item),
        );
        return brandHtmlArray.join(', ');
      }

      return '';
    },
  },
  methods: {
    convertPrice,
    checkPermission,
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.customer[name];
        return $dirty ? !$error : null;
      }
    },
    updateCustomer: function () {
      this.isNew = false;

      this.$v.customer.$touch();
      if (this.$v.customer.$anyError) {
        return;
      }

      const dateOfBirth = this.customer.dateOfBirth
        ? moment(this.customer.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;

      const data = {
        ...this.customer,
        dateOfBirth: dateOfBirth,
      };
      ApiService.setHeader();
      ApiService.put(`customers`, data)
        .then(() => {
          makeToastSuccess('Cập nhật thành công');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getListEmployee: function () {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          const store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    getCustomerById: function () {
      ApiService.setHeader();
      ApiService.get(`customers/${this.customerId}`).then((response) => {
        let dataset = response.data.data;
        this.customer = {
          ...dataset,
          dateOfBirth: new Date(dataset.dateOfBirth) || null,
        };
        this.getDistricts();
        this.getWards();
        this.getStatisticalBillCustomer();
      });
    },
    getStatisticalBillCustomer: function () {
      if (this.phoneNumber) {
        ApiService.setHeader();
        ApiService.get(
          `customer/statistical-bill?customerPhone=${this.phoneNumber}`,
        ).then((response) => {
          const {
            employees,
            store,
            totalAmountBuyedBill,
            brands,
            customerType,
          } = response.data.data;
          this.customer = {
            ...this.customer,
            employees,
            store,
            totalAmountBuyedBill,
            brands,
            customerType,
          };
        });
      }
    },
    getCities: function () {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistricts: function (reload) {
      if (reload) {
        this.customer.district = null;
        this.customer.ward = null;
      }

      if (!this.customer.city) {
        this.listDistrict = [];
        this.listWard = [];
        return;
      }

      ApiService.setHeader();
      ApiService.get(`city/${this.customer.city}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWards: function (reload) {
      if (reload) {
        this.customer.ward = null;
      }

      if (!this.customer.district) {
        this.listWard = [];
        return;
      }

      if (this.customer.district) {
        ApiService.setHeader();
        ApiService.get(`city/${this.customer.district}/ward`).then((data) => {
          this.listWard = data.data.data;
        });
      }
    },
    onSelectedEmployee(option) {
      this.customer.inChargeEmployeeName = option.item.name;
      this.customer.inChargeEmployeeId = option.item.id;
    },
    onInputChangeEmployee(text = '') {
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
  watch: {
    customer(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
