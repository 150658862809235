<template>
  <KTCard ref="preview" v-bind:title="title">
    <template v-slot:title v-if="hasTitleSlot">
      <h3 class="card-title">
        <slot name="title"></slot>
      </h3>
    </template>
    <template v-slot:toolbar>
    </template>
    <!-- -->
    <template v-slot:body>
      <slot name="preview"></slot>
    </template>
    <template v-slot:foot v-if="hasFootSlot">
      <slot name="foot"></slot>
    </template>
    <!-- <template v-slot:foot v-if="hasFootSlot"></template> -->
  </KTCard>
</template>

<script>
import KTCard from '@/view/content/CardTab.vue';

export default {
  name: 'KTCodePreview',
  props: {
    title: String,
  },
  data() {
    return {
      tabIndex: 0,
      isOpen: false,
    };
  },
  components: {
    KTCard,
  },
  mounted() {
    // init example codes
    this.$nextTick(() => {
    
    });
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set current active tab
      event.target.classList.add('active');

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'));
    },
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots['title'];
    },

    /**
     * Check if has foot
     * @returns {boolean}
     */
    hasFootSlot() {
      return !!this.$slots['foot'];
    },
  },
};
</script>
