<template>
  <div class="col-md-12">
    <KTCodePreview>
      <!-- <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <b>Thao tác</b>
              </template>
            </b-dropdown>
          </div>
          <div v-show="checkPermission('CUSTOMER_INSERT')" class="ml-3">
            <router-link to="/customers/add-customer">
              <b-button size="sm" variant="primary">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                <b>Thêm mới</b>
              </b-button>
            </router-link>
          </div>
        </div>
      </template> -->
      <template v-slot:preview>
        <!-- <div class="mb-5">
          <b-row class="mb-3">
            <b-col cols="2">
              <b-input
                size="sm"
                placeholder="Tên"
                v-model="customerName"
                v-on:keyup.enter="onFilter()"
              />
            </b-col>
            <b-col cols="2">
              <b-input
                size="sm"
                placeholder="Điện thoại"
                v-model="customerPhone"
                v-focus
                autocomplete="off"
                v-on:keyup.enter="onFilter()"
              />
            </b-col>
            <b-col cols="2">
              <date-picker
                class="form-control-sm"
                placeholder="Chọn ngày sinh"
                :config="dateConfig.option"
                v-model="customerDOB"
              ></date-picker>
            </b-col>
            <b-col cols="2">
              <b-form-select
                size="sm"
                required
                v-model="customerGender"
                :options="genders"
                value-field="id"
                text-field="name"
                class="select-style"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1">
              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                @click="onFilter"
              >
                Lọc
              </b-button>
            </b-col>
          </b-row>
        </div> -->

        <b-row>
          <b-col cols="12">
            <b-table
              :items="customer.customerPhones"
              :fields="fields"
              bordered
              hover
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template v-slot:cell(fullName)="row">
                {{ customer.fullName }}
              </template>

              <template v-slot:cell(phoneNumber)="row">
                {{ row.item.phoneNumber }}
              </template>

              <!-- <template v-slot:cell(deleted)="row">
                {{ row.item.phoneNumber }}
              </template> -->
              <!-- <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem"
                        class="fas fa-eye icon-color"
                      ></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="!checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-pen icon-color"
                      ></i>
                      &nbsp; Cập nhật
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showDeleteAlert(row.item)"
                    v-show="checkPermission('USER_DELETE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template> -->
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số khách hàng:
              {{ totalItem }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav
                class="custom-pagination"
                v-if="numberOfPage >= 2"
                :link-gen="linkGen"
                :number-of-pages="numberOfPage"
                use-router
                @change="fetchData"
                align="right"
                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                last-class="page-item-last btn btn-icon btn-sm my-1 "
                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
              >
                <template v-slot:first-text>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </template>
                <template v-slot:prev-text>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                </template>
                <template v-slot:next-text>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                </template>
                <template v-slot:last-text>
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </template>
              </b-pagination-nav>
            </b-col>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { formatDate } from '@/utils/common';
export default {
  props: ['customer'],
  components: {
    KTCodePreview,
  },
  data() {
    return {
      fields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '2%' },
          class: 'text-center',
        },
        {
          key: 'fullName',
          label: 'Khách hàng',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'phoneNumber',
          label: 'Điện thoại',
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày thêm',
          thStyle: { textAlign: 'center', width: '10%' },
          formatter: (value) => {
            return formatDate(value, 'DD-MM-YYYY HH:mm:ss');
          },
        },
      ],
    };
  },
};
</script>

<style></style>
